.makeStyles-root-11, .makeStyles-wrapper-56 {
  background: $background;
  .MuiCard-root {
    background: #060607; }
  .MuiTypography-root {
    color: $text-color; } }


.MuiFormLabel-root.Mui-disabled {
  color: white; }

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important; }

.MuiMenu-list {
  background: #C05EC3; }

label.Mui-focused, label.MuiFormLabel-filled {
  color: #FFFFFF !important;
  position: relative;
  top: 5px; }

.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  background: transparent;
  border: none; }
