.body {
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}

.e-view {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.sb-left {
    float: left;
}

.sb-right {
    float: right;
}

.sb-block {
    display: block;
}

.sb-table {
    display: table;
}

.sb-table-cell {
    display: table-cell;
    vertical-align: middle;
}


/*sb-icons declaration*/

@font-face {
    font-family: 'sbicons';
    font-weight: normal;
    font-style: normal;
}

.sb-icons {
    font-family: 'sbicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sb-icon-Hamburger:before {
    content: "\e914";
}

.sb-icon-icon-selection:before {
    content: "\e922";
    padding-left: 4px;
    font-size: 20px;
}

.sb-icon-Dropdown:before {
    content: '\e90f';
    cursor: pointer;
}

.sb-icon-Settings-Preferences:before {
    content: '\e923';
}

.sb-icon-Search:before {
    content: '\e921';
}

.sb-icon-Responsive:before {
    content: '\e920';
}

.sb-icon-Palette:before {
    content: '\e92c';
}

.sb-icon-Chart:before {
    content: '\e906';
}
#sb-content-header .e-tab-text{
    display: table;
}

.sb-icon-Code:before {
    content: '\e92d';
    font-size: 20px;
    display: table-cell;
}

.sb-icon-Demo:before {
    content: '\e92e';
    font-size: 20px;
    display: table-cell;
}

.sb-icon-API:before {
    content: '\e902';
    font-size: 20px;
    display: table-cell;

}
.sb-tab-title{
    display: table-cell;
    vertical-align: middle;
    padding-left: 4px;
}

.sb-icon-Home:before {
    content: '\e915';
    font-size: 30px;
}

.sb-icon-Next:before {
    content: '\e91b';

}

.sb-icons-plnkr:before {
    content: '\e911';
}

.sb-icon-Popout:before {
    content: '\e91d';
}

.sb-icon-Localization:before {
    content: '\e918';
}
.sb-icon-Currency:before{
    content: '\e90b';
}

.sb-icon-Previous:before {
    content: '\e91f';
}

.sb-icon-Back:before {
    content: '\e962';
    font-size: 17px;
}

.sb-icons.sb-icon-Back {
    vertical-align: middle;
    display: table-cell;
    width: 17px;
}

.sb-mobile-setting:before {
    content: '\e901';
    color: #333333;
    font-size: 25px;
}
.highcontrast .sb-mobile-setting:before {
    color: #fff;
}

/**sb-switcher*/

.sb-switch-popup {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    opacity: 100%;
    border-radius: 4px;
    box-shadow: 0 0px 1px -3PX;
;
    /* // outer* x, y - 0px, 1px* blur - 3px; */
    color: #000000;
    opacity: 100;
    font-size: 13px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}


/*sample header*/

.sb-header-settings {
    display: none;
}

div#sample-header {
    z-index: 1001 !important;
}

.sb-header {
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0 8px 11px -6px rgba(0, 0, 0, 0.12);
    z-index: 1001!important;
    opacity: 100;
}
.sb-header-preview {
    height: 100%;
    width: 75px;
}

.sb-header-left,
.sb-header-right {
    height: 100%;
}

.sb-settings-wrapper {
    padding: 0 20px 0px 10px;
}

.sb-search-wrapper {
    padding: 0px 10px 0px 24px;
}

.sb-search-btn,
.sb-setting-btn {
    width: 32px;
    height: 27px;
    text-align: center;
    border-radius: 2px;
}

.sb-search-btn:hover,
.sb-search-btn.active,
.sb-setting-btn:hover,
.sb-setting-btn.active {
    background-color: #d8d8d8;
}

#sb-toggle-left {
    padding-left: 18px;
    font-size: 24px;
}

#sb-header-text {
    padding-left: 22px;
    font-size: 15px;
    color: #000000;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    opacity: 87%;
}
.sb-header-text-right {
    cursor: pointer;
}
.sb-lang-toggler {
    font-size: 20px;
}

.sb-lang-toggler-wrapper {
    padding-top: 1px;
}

.sb-theme-text {
    font-size: 13px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    opacity: 54;
}

.sb-header-right .sb-icons {
    font-size: 20px;
}

.sb-header-splitter {
    float: left;
    border-left: 1px solid rgb(196, 196, 196);
    height: 32px;
    margin-top: 8px;
    padding-right: 22px;
}
#search-input-wrapper .sb-reset-icon {
    cursor: pointer;
    position: absolute;
    top:0px;
    right:0px;
    padding: 13px 13px;
    display: none;
}

#search-input-wrapper:not([data-value='']) .sb-reset-icon{
    display: block;
}

#search-input-wrapper .sb-reset-icon:before {
    content: '\e7e9';
}
.sb-reset-icon{
    color: rgba(0, 0, 0, 0.56);
}

.toggle-active {
    color: #3C78EF;
}

.control-animate {
    transform: translateX(-280px);
}

.control-reverse-animate {
    transform: translateX(280px);
}

.control-transition {
    transition: transform 500ms;
}

.control-fullview {
    left: 0px !important;
}

.sb-download-btn a {
    text-decoration: none;
}

.sb-download-text {
    color: #FFFFFF;
}

.sb-download-wrapper {
    padding-right: 27px;
}
.sb-download-btn:hover .sb-download-btn:focus .sb-download-btn.active {
    border-radius: 2px;
    font-family: "Roboto";
    font-weight: bold;
    color: #FFFFFF;
}
.sb-download-btn:hover {
    background: #006CE6;
}
.sb-download-btn:focus {
    background: #0051CB;
}
.sb-download-btn.active {
    background: #0036B1;
}

.theme-wrapper:hover, .theme-wrapper.active {
    background-color: rgb(216, 216, 216);
}

.theme-wrapper {
    height: 32px;
    width: 150px;
    margin-top: 5px;
    cursor: default;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 2px;
}

.theme-wrapper>div {
    float: left;
    padding-left: 3px;
    line-height: 32px;
    letter-spacing: .5px;
}

.sb-download-btn {
    background-color: #3C78EF;
    opacity: 100;
    border-radius: 2px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-weight: 500;
    line-height: 18px;
    height: 32px;
    border-color: transparent;
}

.sb-header-settings {
    display: none;
}


/*sb-header-popup*/


/*common for popups*/

#search-popup ul, .sb-popups ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.sb-popups li {
    font-size: 14px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}

.switch-text {
    padding-left: 8px;
}

.sb-switch-popup ul {
    padding: 5px 0px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
    border-radius: 4px;
}

.sb-switch-popup {
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 1001;
}

.sb-switch-popup li {
    padding: 10px 38px 12px 15px;
}

.sb-switch-popup li:first-child {
    color: #3C78EF;
}

.sb-switch-popup li:hover {
    background: rgb(238, 238, 238);
}

.sb-switch-popup a {
    text-decoration: none;
    color: black;
    cursor: default;
}

.sb-theme-popup {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
    border-radius: 4px;
    width: 175px;
    z-index: 1001;
}

.sb-theme-popup li {
    line-height: 40px;
    cursor: default;
    padding-top: 4px;
}

.sb-theme-select {
    padding-left: 12px;
    vertical-align: middle;
    color: #ffffff;
}

.sb-theme-popup li:hover {
    background: rgb(238, 238, 238);
}

.sb-theme-popup li:hover .sb-theme-select {
    color: rgb(238, 238, 238);
}

.sb-theme-popup li.active .sb-theme-select {
    color: rgb(255, 109, 63);
}

.sb-theme-popup li:hover.active .sb-theme-select {
    color: rgb(255, 109, 63);
}

.e-search-overlay {
    z-index: 10000022;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(126, 125, 125, 0.78);
}

#search-input-wrapper {
    width: 75%;
    height: 38px;
    margin-top: 50px;
    margin-left: 13.5%;
    position: relative;
}

#search-input-wrapper input {
    border-radius: 4px;
    border-color: transparent;
    padding-left: 6px;
    outline: none;
    width: 100%;
    height: 100%;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}

.sb-search-result {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
    border-radius: 4px;
    width: 75%;
}

.sb-search-result .e-list-group-item {
    background-color: #f7f7f7;
    font-size: 15px;
    vertical-align: middle;
    line-height: 36px;
    border-left: none;
    border-right: none;
    text-transform: uppercase;
    font-weight: 500;
}

#search:hover {
    background-color: #007dd1;
}

.sb-search-result {
    overflow-y: auto;
    max-height: 75%;
}

.sb-search-result .e-list-parent {
    margin-bottom: 0px;
}

.sb-search-result .e-list-item {
    line-height: 36px;
}

.sb-search-result .e-list-item:hover {
    background-color: #e2f2ff;
    cursor: default;
}

.sb-search-result li {
    padding-left: 6px;
    font-size: 13px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-weight: 400;
}

.search-no-record {
    padding: 16px 5px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-weight: 700;
    cursor: default;
}


/*settings popup*/

.sb-setting-popup .sb-setting-header {
    padding-top: 12px;
    padding-left: 16px;
    padding-bottom: 12px;
    line-height: 15px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid #CCCCCC;
}

.sb-setting-popup {
    width: 242px;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
    border-radius: 4px;
    cursor: default;
}

.setting-label {
    padding-bottom: 5px;
}

.sb-setting-content {
    padding: 4px 16px 4px 16px;
}

.setting-label {
    font-size: 14px;
    display: table;
}

.setting-label div {
    display: table-cell;
}

.sb-setting-text {
    position: relative;
    top: -2px;
}

.sb-setting-icons {
    padding-right: 14px;
    font-size: 18px;
    color: #9e9e9e;
}

.set-border-radious-touch {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.set-border-radious-mouse {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.setting-responsive {
    display: table;
}

.setting-responsive .sb-responsive-items:hover {
    border-color: rgba(239, 230, 230, 0.2);
}

.setting-responsive .sb-responsive-items {
    width: 104px;
    background-color: #e8e8e8;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    border: 1px solid transparent;
    display: table-cell;
    cursor: pointer;
}

.setting-responsive .sb-responsive-items:first-child {
    border-right: none;
}

.setting-responsive .sb-responsive-items:last-child {
    border-left: none;
}

.setting-responsive .sb-responsive-items.active {
    background-color: #1783ff;
    color: white;
}

.sb-setting-theme-section {
    display: none;
}

.sb-setting-item{
    padding-bottom: 12px;
}

/*sample-content*/

.sb-content {
    top: 48px;
    background: #F8F8F8;
}

.copycode::before {
    content: '\e70a';
    font-size: 25px;
}

.bootstrap4 .copycode::before{
    content:'\e77b';
    font-size:25px;
}

.copy-tooltip {
    height: 32px;
    width: 32px;
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 12px;
    background: #f4f4f4;
    border-radius: 2px;
}

.copy-tooltip.e-control.e-tooltip:hover {
    background: #D8D8D8;
}

.e-icons.copycode {
    padding-left: 3px;
    padding-top: 4px;
    color: rgba(0, 0, 0, .54);
}

/*
* SB-Home
*/

.sb-home {
    display: table;
    width: 100%;
    padding: 12px 16px;
    height: 66px;
    position: relative;
}

.sb-home:hover {
    cursor: pointer;
}

.sb-home-link {
    display: table-cell;
    width: 14px;
    color:#717171;
}

.sb-home-text {
    position: absolute;
    top: 27px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-size: 15px;
    font-weight: 500;
    padding-left: 16px;
}


/*left-pane*/
#left-sidebar {
    border: none;
    background-color: #f8f8f8;
}


.sb-badge{
    border-radius: 2px;
    font-size: 10px;
    color: #ffffff;
    padding: 6px 8px;
    float: right;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
}
.e-samplestatus{
    cursor:pointer;
}
.e-samplestatus.new, .e-samplestatus.New {
    background-color: #29bc4f;
}

.e-samplestatus.update, .e-samplestatus.Update {
    background-color: #fbaa33;
}

.e-samplestatus.preview, .e-samplestatus.Preview {
    background-color: #0275d8;
}

.e-samplestatus.new, .e-samplestatus.New,
.e-samplestatus.update, .e-samplestatus.Update,
.e-samplestatus.preview, .e-samplestatus.Preview {
    margin-top: 6.5px;
    margin-bottom: 6.5px;
}

.e-bigger .e-samplestatus.new, .e-bigger .e-samplestatus.New,
.e-bigger .e-samplestatus.update, .e-bigger .e-samplestatus.Update,
.e-bigger .e-samplestatus.preview, .e-bigger .e-samplestatus.Preview {
    margin-top: 13px;
    margin-bottom: 13px;
}

.e-samplestatus.new.tree, .e-samplestatus.New.tree,
.e-samplestatus.update.tree, .e-samplestatus.Update.tree,
.e-samplestatus.preview.tree, .e-samplestatus.Preview.tree {
    margin-top: 4px;
    margin-bottom: 4.5px;
    margin-right: -10px;
}

.e-bigger .e-samplestatus.new.tree, .e-bigger .e-samplestatus.New.tree,
.e-bigger .e-samplestatus.update.tree, .e-bigger .e-samplestatus.Update.tree,
.e-bigger .e-samplestatus.preview.tree, .e-bigger .e-samplestatus.Preview.tree {
    margin-top: 8px;
    margin-bottom: 10px;
    margin-right: -10px;
}

.sb-left-pane {
    width: 280px;
    height: 100%;
    overflow-x: hidden;
}

#controlSamples {
    top: 66px;
}

#controlList.e-content {
    background: transparent;
}

.sb-left-pane .e-listview, .sb-left-pane .e-treeview {
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}

.sb-left-pane .e-listview .e-list-item {
    background: transparent;
    border: none;
}

.sb-left-pane .e-listview .e-list-item.e-hover {
    background-color: #d2ebff;
}

.sb-left-pane .e-listview .e-list-group-item {
    border: none;
    color: #000000;
    opacity: 87;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-weight: 700;
    background: transparent;
}

.sb-left-pane .e-listview .e-list-item.e-active {
    background-color: #d2ebff;
    color: #000000;
}

.fabric .sb-left-pane .e-listview .e-list-item.e-active {
    background-color: #d2ebff;
}

.sb-left-pane .e-treeview .e-list-item.e-hover > .e-fullrow {
    background-color: #d2ebff;
}
.highcontrast .sb-left-pane .e-treeview .e-list-item.e-hover>.e-fullrow {
    background-color: #bf5cc3;
}

#controlList .e-list-group-item .e-list-text {
    font-size: 16px;
    font-weight: 800;
}

#controlTree ul.e-list-parent {
    padding-left: 10px;
}
#controlTree ul.e-list-parent.e-ul {
    overflow-y: hidden;
}
.sb-left-pane #sb-left-back {
    padding: 12px 20px;
    width: 100%;
    display: table;
    cursor: pointer;
}

.sb-adjust-juggle {
    top: 50px;
}

#controlList {
    overflow-y: auto;
    padding-left: 5px;
    -webkit-overflow-scrolling: touch;
    border: none;
}

.sb-control-list-top {
    top: 46px;
}

#controlTree {
    overflow-y: auto;
    top: 66px;
    -webkit-overflow-scrolling: touch;
}

.sb-left-back {
    height: 50px;
}

#controlList .e-content {
    background-color: transparent;
}

.back-icon::before {
    content: '\e962';
    font-size: 17px;
}

.back-icon {
    vertical-align: middle;
    display: table-cell;
    width: 17px;
}

.property-section {
    padding-bottom: 15px;
}

.control-name {
    display: table-cell;
    padding-left: 25px;
    font-weight: 400;
    font-size: 16px;
    color: #1763ff;
}

.sb-left-pane-header, .sb-left-pane-footer {
    display: none;
}


/*right -pane*/

.sb-right-pane {
    /*left: 280px;*/
    padding-left: 26px;
    padding-right: 26px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.sb-content-section {
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    opacity: 100;
    height: 100%;
}

.sb-content-section .sb-content-tab-header {
    border-bottom: 1px solid #D7D7D7;
}

.sb-source-section .e-tab .e-header .e-indicator {
    display: none;
}

.sb-source-section .e-tab .e-header {
    border-top: 1px solid #D7D7D7;
}

.sb-source-section .e-tab .e-header .e-toolbar-item.e-active .e-tab-text {
    color: #3C78EF;
}

#sb-source-tab .e-tab-text {
    text-transform: lowercase;
}

.sb-right-pane-overlay {
    pointer-events: none;
}

.description-section {
    padding-top: 32px;
}

#sb-source-tab>#sb-content-header .e-tab-text {
    text-transform: lowercase;
}

.description-header {
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    padding-bottom: 10px;
    opacity: 87;
    font-weight: 500;
    line-height: 21px;
    color: #000000;
}

.control-section {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 350px;
}

.sb-sample-content-area {
    min-height: 350px;
}

.sb-src-code {
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    white-space: pre;
    padding: 10px;
    overflow-x: auto;
    min-height: 320px;
    font-size: 13px;
}

.sb-bread-crumb {
    padding-top: 6px;
    padding-bottom: 27px;
}

.sb-action-description {
    padding-bottom: 16px;
}

.sb-component-name h1{
    padding-top: 24px;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
    font-weight: 600;
    line-height: 24px;
    font-size: 18px;
    margin: 0;
}


.sb-bread-crumb-text {
    display: table;
}

.sb-bread-crumb .sb-bread-crumb-text>div {
    font-size: 13px;
    font-weight: 400;
    padding-right: 8px;
    cursor: default;
    display: table-cell;
    color: #898989;
}

.sb-bread-crumb-text {
    line-height: 21px;
}

.sb-plnr-section {
    padding-right: 17px;
    font-size: 15px;
    height: 38px;
    line-height: 35px;
    color: #3C78EF;
    border: none;
    background: none;
}

.sb-desktop-setting {
    display: inline-flex;
}

.sb-mobile-setting {
    display: none;
}

.sb-plnkr-text {
    font-size: 15px;
}

.sb-icons-plnkr {
    padding-right: 3px;
    top: 1px;
    position: relative
}

.sb-plnr-section:hover {
    color: #1783ff;
    cursor: pointer;
}

.sb-toolbar-splitter {
    border-left: 1px solid rgb(196, 196, 196);
    height: 31px;
    margin-top: 3px;
}

.e-bigger .sb-content-toolbar {
    padding: 5px 0px;
}

.e-bigger .sb-toolbar-splitter {
    height: 34px;
}

.sb-content-toolbar>div {
    padding-right: 15px;
}

.sample-navigation button:disabled {
    cursor: not-allowed;
    color: #5a5a5a;
}

.sample-navigation, .sb-open-new-wrapper {
    font-size: 25px;
    line-height: 40px;
    color: #5a5a5a;
    cursor: pointer;
}

.sample-navigation>button {
    background: transparent;
    border: none;
}

#next-sample ,#prev-sample {
    font-size:25px;
}

.sample-navigation>button:hover {
    color: #1783ff;
}

button#tab-navigation-prev {
    padding-right: 15px;
}

.sb-custom-item.back-navigation {
    line-height: 37px;
}

.sb-custom-item.back-navigation {
    display: inline-flex;
}

.sb-open-new-wrapper {
    padding: 0px 16px;
}

.sb-open-new-wrapper a {
    color: #5a5a5a;
    text-decoration: none;
}

.sb-open-new-wrapper a:hover {
    color: #1783ff;
}

.sb-content-toolbar {
    position: absolute;
    right: 0px;
    top: 0px;
    display: inline-flex;
}

.sb-bread-crumb-text>div.seperator {
    font-weight: 500;
    font-size: 13px;
    font-family: 'sbicons';
    vertical-align: bottom;
}

.sb-source-code-section .e-header .sb-api-section {
    padding: 20px;
}

.e-custDesription {
    max-height: 45px;
    text-overflow: ellipsis;
    white-space: normal;
}

.e-custDesription .sb-api-content {
    overflow: hidden;
    height: 36px;
}

.e-display {
    display: none;
}

.sb-sample-description {
    white-space: normal;
}


/*
propertye pane
*/

.property-panel-header {
    padding-top: 22px;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
}

.property-panel-section {
    padding-left: 12px;
}

.sb-property-border {
    position: absolute;
    height: 100%;
    border: 0.5px solid #D7D7D7;
    top: 0px;
}

.sb-property-border.sb-prop-md-4 {
    left: 66.66666667%;
}

.sb-property-border.sb-prop-md-3 {
    left: 75%;
}

.property-panel-table td {
    width: 50%;
    margin-top: 3px;
}

.property-panel-table {
    width: 100%;
}

.property-panel-table div {
    padding-left: 10px;
    padding-top: 10px;
}


/*sb-footer*/

.sb-footer {
    height: 100px;
    width: calc(100% + 36px);
    position: relative;
    border-top: 1px solid #D5D5D5;
    margin-top: 24px;
}

.sb-footer-left {
    position: absolute;
}

.sb-footer-links a {
    color: #1783FF;
    text-decoration: none;
    cursor: pointer;
}

.sb-footer-links {
    display: table;
}

.sb-footer-link {
    display: table-cell;
    padding: 20px 30px 12px 0px;
}

.sb-footer-copyright {
    padding-bottom: 24px;
}

.sb-footer-logo {
    padding: 36px 36px 27px 0px;
    right: 0px;
    position: absolute;
}

.sb-footer-logo-icon {
    height: 40px;
    width: 140px;
}

.sb-highlight {
    font-weight: bold;
}
.sb-ts-snippet-header {
    color: #3C78EF;
}

/*Hide property pane */

@media (max-width: 1200px) {
    .sb-property-border {
        border: none;
    }
    .property-section {
        border-left: none;
    }
}

/*Tab view*/

@media (max-width: 900px) {
    .e-sb-header-text>.sb-header-text-left {
        display: none;
    }
    .sb-desktop-setting {
        display: inline-flex;
    }
    .sb-desktop-setting.sb-hide {
        display: block;
    }
    .sb-toolbar-splitter {
        display: none;
    }
    .sb-plnr-section {
        display: none;
    }
    .copy-tooltip {
        display: none;
    }
    .control-section {
        min-height: auto;
    }
}
@media (max-width: 764px) {
    .theme-wrapper {
        display: none;
    }
    .sb-setting-theme-section {
        display: block;
    }
    .sb-responsive-section {
        display: none;
    }
}

.sb-mobilefooter {
    display: none;
}


/* Mobile View */

@media (max-width: 550px) {
    .e-ddl.e-popup, #sb-setting-theme_popup {
        z-index: 100001;
    }
    .sb-left-pane {
        position: fixed;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    #controlSamples {
        position: relative;
        top: 0px;
    }
    #controlSamples.control-samples-animate {
        position: absolute;
        top: 116px;
    }
    #controlList {
        overflow-y: hidden;
        position: unset;
    }
    #controlTree {
        top: 0px;
        position: unset;
        overflow: hidden;
    }
    .sb-component-name {
        padding-top: 12px;
        padding-bottom: 5px;
    }
    .sb-bread-crumb {
        padding-top: 6px;
        padding-bottom: 24px;
        font-size: 13px;
    }
    .sb-right-pane {
        padding-left: 0px;
        padding-right: 0px;
    }
    .sb-rightpane-padding {
        padding-left: 10px;
        padding-right: 5px;
    }
    .sb-content-section {
        border-left: none;
        border-right: none;
    }
    .sb-download-splitter, .sb-download-wrapper {
        display: none;
    }
    .sb-header-text-left {
        display: none;
    }
    #search-input-wrapper {
        width: calc(100% - 24px );
        margin-left: 12px;
    }
    .sb-header-settings {
        display: block;
        padding-right: 24px;
        padding-top: 6px;
    }
    .sb-header-settings:before {
        content: '\e923';
        font-size: 24px;
    }
    .sb-settings-wrapper {
        display: none;
    }
    .sb-mobile-left-pane {
        z-index: 10000;
        height: 100%;
        width: 280px;
    }
    .sb-mobile-overlay {
        width: 100%;
        position: fixed;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 110;
    }
    .sb-search-result{
        width:calc(100% - 24px);
    }

    /*sb-mobile-right-pane*/
    .sb-mobile-right-pane {
        background: #fbfbfb;
        position: relative;
        width: 280px;
        height: 100%;
        float: right;
        z-index: 10000;
    }
    .highcontrast .sb-mobile-right-pane {
        background: #000000;
    }
    .sb-mobile-preference {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .sb-setting-header {
        padding: 16px 0px 16px 18px;
        font-weight: 500 !important;
        line-height: 35px !important;
    }
    .sb-setting-content {
        padding-left: 18px;
        padding-top: 0px;
    }
    .sb-setting-theme-section {
        display: block;
        padding: 16px 0px;
    }
    .sb-icon-Palette {
        padding-right: 10px;
    }
    .sb-icon-Palette:before {
        font-size: 25px;
    }
    .sb-setting-text {
        font-size: 16px;
    }
    #sb-setting-theme, #setting-currency, #sb-setting-culture {
        width: 100%;
        height: 32px;
        border-radius: 3px;
    }
    .sb-setting-item {
        padding: 16px 0px;
    }
    .sb-icon-Responsive {
        padding-right: 10px;
    }
    .sb-icon-Responsive:before {
        font-size: 25px;
    }
    .setting-content.btn-group.setting-responsive {
        width: 244px;
    }
    #settings-popup {
        left: 0px !important;
        top: 0px !important;
        background: transparent;
        border: none;
        box-shadow: none;
        width: 280px;
        height: 100%;
    }
    .property-panel-section,
    .property-section {
        padding-left: 0px;
        padding-right: 0px;
    }
    .property-panel-header {
        padding: 22px 0px 0px 20px;
        font-size: 16px;
        font-weight: 600;
        height: 60px;
        border-bottom: 1px solid #CCCCCC;
    }
    .property-panel-content {
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-top: 12px !important;
    }
    /*sb-left-pane*/
    .sb-left-pane-header, .sb-left-pane-footer {
        display: block;
    }
    /*source tab header*/
    .sb-desktop-setting {
        display: inline-flex;
    }
    .sb-desktop-setting.sb-hide {
        display: block;
    }
    .sb-toolbar-splitter {
        display: none;
    }
    .sb-plnr-section {
        display: none;
    }
    .sb-right-pane {
        left: 0px;
        padding-bottom: 32px;
        height: 93%;
    }
    .sample-navigation {
        display: none;
    }
    .sb-mobilefooter>.e-btn.e-flat {
        color: #5a5a5a;
        border: none;
        background-color: transparent;
        width: 50%;
        text-align: center;
        height: 100%;
    }
    .highcontrast .sb-mobilefooter>.e-btn.e-flat {
        background: #000;
        color: #fff;
    }
    .sb-mobilefooter>.e-btn.e-disabled:hover {
        color: #5a5a5a;
    }
    .sb-mobilefooter>.e-btn.e-flat:hover {
        color: #1783ff;
    }
    .highcontrast .sb-mobilefooter>.e-btn.e-flat:hover {
        color: #bf5cc3;
    }
    .sb-mobilefooter>.e-btn.e-disabled:hover {
        color: #5a5a5a;
    }
    .sb-mobilefooter {
        top: 91%;
        background-color: #e6e4e4;
        font-size: 15px;
        display: inline-block;
        z-index: 20;
    }
    .sb-mobilefooter>button:focus {
        outline: none;
    }
    .sb-mobile-setting {
        display: block;
        padding-top: 5px;
    }
    .sb-open-new-wrapper {
        padding-top: 3px;
    }
    .sb-icon-Demo, .sb-icon-Code ,.sb-icon-API{
        display: none;
    }
    /*sb-right-pane*/
    .sb-right-pane {
        left: 0px;
        padding-bottom: 32px;
    }
    .sb-left-pane {
        z-index: 10010;
        background: #f8f8f8;
    }
    .highcontrast .sb-left-pane {
        background: #000000;
    }
    .sb-footer-link {
        padding: 20px 16px 0px 16px;
    }
    .sb-footer-copyright {
        padding: 16px 16px 10px;
    }
    .sb-ej2 {
        display: table;
        padding: 16px 16px 4px;
    }
    .sb-mobile-logo {
        width: 27px;
        height: 27px;
        display: table-cell;
    }
    .sb-name {
        display: table-cell;
        font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
        font-size: 21px;
        font-weight: 500;
        padding-left: 10px;
    }
    .sb-mobile-header-buttons {
        display: table;
        width: 100%;
        padding-top: 20px;
        border-top: 1px solid #CCCCCC;
    }
    .sb-mobile-header-price {
        width: 114px;
        background-color: #3C78EF;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        border: 1px solid transparent;
        cursor: pointer;
        display: inline-table;
        margin-left: 16px;
        padding: 6px 12px;
        color: #ffffff;
        border-radius: 2px;
    }
    .sb-mobile-header-about {
        width: 114px;
        background-color: #ffffff;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        border: 1px solid;
        cursor: pointer;
        display: inline-table;
        margin-left: 16px;
        padding: 6px 12px;
        color: #3C78EF;
        border-radius: 2px;
    }

    .sb-mobile-header-button:active {
        background-color: #1783FF;
    }
    .sb-footer {
        display: none;
    }
    .atlwdg-trigger.atlwdg-SUBTLE {
        bottom: 43px!important;
    }
    .atlwdg-popup {
        width: 100% !important
    }
    span.sb-icon-Next, span.sb-icon-Previous {
        font-size: 18px !important;
    }

}


/* #sb-content.e-tab>.e-header .e-indicator{
    background-color:rgb(255, 109, 63);
}
#sb-content.e-tab>.e-header .e-toolbar-item.e-active .e-tab-text{
    color:rgb(255, 109, 63);
} */

.sb-body-overlay {
    z-index: 10000023;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    pointer-events: none;
}

.sb-content-overlay {
    z-index: 10000022;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
    pointer-events: none;
    left: 0px;
}

.sb-loading {
    width: 56px;
    height: 56px;
    position: absolute;
    top: calc(50% - 28px);
    left: calc(50% - 28px);
    z-index: 10000;
    border-radius: 50%;
    padding: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    display: inline-block;
    background: white;
}

.circular {
    animation: rotate 2s linear infinite;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #ff4081;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}


/*sb-hide */

.sb-hide {
    display: none;
}
/* high-contrast theme */

.highcontrast .sb-content {
    background: #000000;
}
.highcontrast .sb-search-result .e-list-item {
    background: black;
    height: 45px;
    line-height: 38px;

}
.highcontrast .sb-search-result .e-list-group-item {
    color: #bf5cc3;
    background: black;
    height: 45px;
    line-height: 38px;
    border-top: 2px solid #969696;

}
.highcontrast code,
.highcontrast .sb-switch-popup,
.highcontrast .sb-header,
.highcontrast .sb-setting-popup,
.highcontrast .sb-search-result,
.highcontrast .sb-src-code,
.highcontrast .sb-api-section,
.highcontrast .sb-theme-popup,
.highcontrast .copy-tooltip,
.highcontrast .e-icons.copycode,
.highcontrast #search-input-wrapper input,
.highcontrast #search-input-wrapper input::placeholder {
    color: #ffffff;
    background: #000000;
    border-bottom: 1px solid #969696;
}

.highcontrast .sb-theme-select {
    color: #000000;
}
body.highcontrast,
.highcontrast #sb-header-text,
.highcontrast .control-name,
.highcontrast .sample-navigation,
.highcontrast .sb-icon-Popout,
.highcontrast .sb-switch-popup a {
    color: #ffffff;
}
.highcontrast .sb-body-overlay {
    background-color: #000000
}
.highcontrast a,
.highcontrast a:hover {
    color: #1783FF;
}
.highcontrast #search-input-wrapper input {
    background: #000000;
    border-bottom: 1px solid #969696;
}

.highcontrast .hljs-string,
.highcontrast .hljs-title,
.highcontrast .hljs-section,
.highcontrast .hljs-attribute,
.highcontrast .hljs-literal,
.highcontrast .hljs-template-tag,
.highcontrast .hljs-template-variable,
.highcontrast .hljs-type,
.highcontrast .hljs-addition,
.highcontrast .e-icons.back-icon,
.highcontrast .sb-plnr-section,
.highcontrast .sb-bread-crumb,
.highcontrast .sb-component-name,
.highcontrast .sb-icon-Hamburger,
.highcontrast .sb-icon-Home,
.highcontrast .description-header,
.highcontrast .sb-setting-translate,
.highcontrast .sample-navigation>button:hover,
.highcontrast .sb-switch-popup li:first-child,
.highcontrast .sb-left-pane .e-listview .e-list-group-item,
.highcontrast .sb-theme-popup li.active .sb-theme-select {
    color: #bf5cc3;
}
.highcontrast .sb-left-pane .e-listview .e-list-item.e-active {
    background: #bf5cc3;
    color: #000000;
}

.highcontrast .theme-wrapper>div {
    padding-left: 2px;
}

.highcontrast #left-sidebar {
    color: #ffffff;
    background: #000000;
}

.highcontrast #left-sidebar {
    border: none;
}

.highcontrast .sb-header {
    background: #000000;
    border-bottom: 1px solid #969696;
}
.highcontrast .sb-switch-popup {
    background: #000000;
}
.highcontrast .sb-theme-popup {
    background: #000000;
}

.highcontrast .sb-content-section,
.highcontrast .sb-switch-popup .highcontrast .sb-content-tab-header,
.highcontrast .sb-content-section .sb-content-tab-header {
    background: #000000;
    border-color: #969696;
}

.highcontrast .sb-footer {
    border-color: #969696;
    background-color: #000000;
}

.highcontrast .sb-search-btn:hover,
.highcontrast .sb-search-btn.active,
.highcontrast .sb-setting-btn:hover,
.highcontrast .sb-setting-btn.active,
.highcontrast .sb-switch-popup li:hover,
.highcontrast .theme-wrapper:hover,
.highcontrast .theme-wrapper.active,
.highcontrast .sb-theme-popup li:hover,
.highcontrast .sb-search-result .e-list-item:hover,
.highcontrast .sb-left-pane .e-listview .e-list-item:hover {
    background-color: #685708;
}
.highcontrast .sb-search-btn:hover,
.highcontrast .sb-search-btn.active,
.highcontrast .sb-setting-btn:hover,
.highcontrast .sb-setting-btn.active {
    border: 1px solid #969696;
}

.highcontrast .sb-switch-popup li:hover,
.highcontrast .sb-search-result .e-list-item:hover {
    border-left: none;
    border-right: none;
    border-bottom: 2px solid white;
    border-top: 2px solid white;
}

.highcontrast .setting-responsive .sb-responsive-items.active {
    background: #bf5cc3;
    color: #000000;
}

.highcontrast .setting-responsive .sb-responsive-items {
    background: #333333;
    color: #ffffff;
}

.highcontrast .hljs-keyword,
.highcontrast .hljs-selector-tag,
.highcontrast .hljs-built_in,
.highcontrast .hljs-name,
.highcontrast .hljs-tag,
.highcontrast .sb-source-section .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
    color: #00ff00;
}
.highcontrast #search-input_popup .e-list-group-item,
#search-input_popup .e-fixed-head {
    background-color: #bf5cc3;
    color: #000;
}
.highcontrast #controlList li.e-list-item {
    line-height: 45px;
}

.sb-left-pane .e-treeview .e-list-text {
    width: 100%;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}

.sb-left-pane .e-treeview .e-text-content{
    padding-right: 16px;
}

.tree-text{
    float: left;
}

.highcontrast .sb-setting-popup {
    background: #000000;
}
.highcontrast .sb-src-code {
    background: #000000;
    color: #ffffff;
}
.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-indicator
{
    background: #0278d7;
    display: block;
}

.highcontrast .sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-indicator
{
    background: #bf5cc3;
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item.e-active > .e-tab-wrap > .e-ripple-element
{
    background: rgba(2,120,215, 0.1);
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item.e-active > .e-tab-wrap > .e-text-wrap > .e-tab-text,
.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap > .e-text-wrap > .e-tab-text:hover {
    color: #0278d7;
}

.highcontrast .sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item.e-active > .e-tab-wrap > .e-text-wrap > .e-tab-text,
.highcontrast .sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap > .e-text-wrap > .e-tab-text:hover {
    color: #bf5cc3;
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap > .e-text-wrap > .e-tab-text {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
}

.highcontrast .sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap > .e-text-wrap > .e-tab-text {
    color: #ffffff;
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item.e-active,
.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item.e-active > .e-tab-wrap > .e-text-wrap::before {
    border: none;
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap {
    padding-left: 12px;
    padding-right: 12px;
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap > .e-text-wrap > .e-tab-text > .sb-tab-title {
    padding-left: 8px;
}

.sb-content-tab.e-tab > .e-nested > .e-tab-header > .e-items.e-toolbar-items > .e-toolbar-item > .e-tab-wrap:hover {
    background: transparent;
}
/*
RTE toolbar not visible in fullscreen
*/
.e-control.e-richtexteditor.e-rte-full-screen {
    z-index: 1010;
}

/* new icon declaration*/

@font-face {
    font-family: 'sb-new-icons';
    font-weight: normal;
    font-style: normal;
}

.sb-new-icons {
    font-family: 'sb-new-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ad-img {
    background-size: cover;
    border-radius: 6px;
    color: #fff;
    margin: 15px 0px;
    width: 100%;
    height: 121px;
    font-family: "Open Sans";
}
.ad-header {
    font-weight: 600;
    font-size: 23px;
    color: #FFFFFF;
    letter-spacing: -0.39px;
    width: calc(100% - 30px);
    /* text-align: center; */
    height: 32px;
    padding: 21px 0 0 40px;
}

.trial-btn{
    padding: 12.5px 4px !important;
}

.free-trial-link {
    position: relative;
    bottom: 1px;
}

.free-trial {
    width: 176px;
    height: 43px;
    background-color: #FF4800;
    border-radius: 21.5px;
    margin: -52px 20px 0px 0px;
    float: right;
    font-size: 15px;
    font-weight: 600;
    padding: 11px 29px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.20);
    cursor: pointer;
}

.free-trial:hover {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.50);
}

.ad-wrapper {
    width: 100%;
    padding: 15px 0 15px 0;
}

.content-area {
    margin-left: 37px;
    margin-top: 22px;
}

.ad-cnt-pt {
    display: table-cell;
}

.click-icon {
    width: auto;
    height: 20px;
    display: table-cell;
    float: left;
}

.ad-cnt-icon {
    padding: 3px;
    position: relative;
    top: 5px;
    color: #F8E71C;
}

.cnt-text {
    padding-left: 6px;
    padding-right: 10px;
    padding-top: 9px;
    display: table-cell;
}

.cnt-area {
    padding-top: 10px;
}

@media only screen and (min-width: 1350px) and (max-width: 1450px) {
    .ad-img {
        height: 179px;
    }

    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 83px;
        margin-top: 32px;
    }
    .cnt-text {
        padding-left: 2px;
        padding-right: 14px;
        padding-top: 9px;
        display: table-cell;
        text-align: -webkit-center;
    }
}


@media only screen and (min-width: 1250px) and (max-width: 1349px) {
    .ad-img {
        height: 179px;
    }

    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 83px;
        margin-top: 32px;
    }
    .cnt-text {
        padding-left: 2px;
        padding-right: 14px;
        padding-top: 9px;
        display: table-cell;
        text-align: -webkit-center;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1249px) {
    .ad-img {
        height: 192px;
    }

    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 53px;
        margin-top: 47px;
    }
    .cnt-text {
        padding-left: 2px;
        padding-right: 14px;
        padding-top: 9px;
        display: table-cell;
        text-align: -webkit-center;
    }
}

@media only screen and (min-width: 1150px) and (max-width: 1200px) {
    .ad-img {
        height: 210px;
    }

    .ad-header {
        margin-top: -13px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 31px;
        margin-top: 52px;
    }
    .cnt-text {
        padding-left: 2px;
        padding-right: 14px;
        padding-top: 9px;
        display: table-cell;
        text-align: -webkit-center;
        max-width: 215px;
    }
}

@media only screen and (min-width: 1050px) and (max-width: 1149px) {
    .ad-img {
        height: 218px;
    }

    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 66px;
        margin-top: 48px;
    }
    .cnt-text {
        max-width: 190px;
    }
}

@media only screen and (min-width: 950px) and (max-width: 1049px) {
    .ad-img {
        height: 230px;
    }

    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 11px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 6px;
        margin-top: 63px;
    }
    .cnt-text {
        max-width: 194px;
        padding-left: 0px;
        padding-right: 4px;
        padding-top: 8px;
        display: table-cell;
    }
}

@media only screen and (min-width: 850px) and (max-width: 949px) {
    .ad-img {
        height: 256px;
    }

    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(106% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 0px;
        margin-top: 61px;
    }
    .cnt-text {
        max-width: 146px;
        font-size: 13px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 9px;
        display: table-cell;
    }
}

@media only screen and (min-width: 750px) and (max-width: 849px) {
    .ad-img {
        height: 236px;
    }
    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 19px;
        margin-top: 61px;
    }
    .cnt-text {
        max-width: 217px;
    }
}

@media only screen and (min-width: 650px) and (max-width: 749px) {
    .ad-img {
        height: 236px;
    }
    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 19px;
        margin-top: 61px;
    }
    .cnt-text {
        max-width: 217px;
    }
}

@media only screen and (min-width: 550px) and (max-width: 649px) {
    .ad-img {
        height: 261px;
    }
    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
        padding: 10px 0 0 24px;
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 19px;
        margin-top: 61px;
    }
    .ad-cnt-pt {
        display: flex;
    }
}

@media only screen and (min-width: 450px) and (max-width: 549px) {
    .ad-img {
        height: 261px;
    }
    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(100% - 30px);
        padding: 10px 0 0 24px;
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 19px;
        margin-top: 61px;
    }
    .ad-cnt-pt {
        display: flex;
    }
}

@media only screen and (min-width: 350px) and (max-width: 449px) {
    .ad-img {
        height: 330px;
    }
    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(101% - 30px);
        padding: 15px 0 0 24px;
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 19px;
        margin-top: 97px;
    }
    .ad-cnt-pt {
        display: flex;
    }
}

@media only screen and (min-width: 300px) and (max-width: 349px) {
    .ad-img {
        height: 313px;
    }
    .ad-header {
        margin-top: -4px;
        text-align: center;
        width: calc(103% - 30px);
        padding: 15px 0px 0px 8px;
        font-size: 21px;
    }
    .free-trial{
        display: block;
        margin: 17px auto 0px;
        float: none;
        width: 238px;
        padding: 11px 61px;
    }
    .content-area {
        margin-left: 19px;
        margin-top: 90px;
    }
    .ad-cnt-pt {
        display: flex;
    }
}

.sb-npm-btn:hover {
    background-color: #f4feff;
}

.sb-npm-btn {
    display: none;
    background: #fff;
    border: 1px solid #3c78ef;
    border-radius: 3px;
    width: 119px;
    height: 32px;
    float: right;
    color: #3c78ef;
    margin: 0px 0 0 11px;
    cursor: pointer;
    display:none;
}

.doc-npm-link {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    bottom: -0.9px;
    left: 4px;
    position: relative;
}
#icon-npm:before {
    content: "\e900";
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
    position: relative;
    top: 0.8px;
    right: 4px;
    color: #3c78ef;
}

.npm-svg {
    position: relative;
    bottom: -1px;
    width: 20%;
    right: 3px;
}

.tailwind .e-samplestatus.new.tree, .tailwind .e-samplestatus.update.tree, .tailwind .e-samplestatus.preview.tree {
    margin: 12px 0;
    height: 22px;
    margin-top: 8px;
}

.tailwind .e-samplestatus.new, .tailwind .e-samplestatus.update, .tailwind .e-samplestatus.preview {
    margin: 12px 0;
    height: 22px;
    margin-top: 2px;
}